import React, {
  Suspense,
  lazy,
  startTransition,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import {
  ConstantsProvider,
  GlobalStateProvider,
} from "./components/shared/GlobalsContext";

import "./components/shared/style.css";
import { NavigationProvider } from "./components/shared/NavigationContext";
import { ImageProvider } from "./components/shared/ImageContext";
import PlaySounds from "./components/shared/PlaySounds";

// Lazy load components
const Home = lazy(() => import("./components/pages/Home/Home"));
const Projects = lazy(() => import("./components/pages/Projects/Projects"));
const Explorations = lazy(() =>
  import("./components/pages/Explorations/Explorations")
);
const About = lazy(() => import("./components/pages/About/About"));
const Contact = lazy(() => import("./components/pages/Contact/Contact"));
const Experimental = lazy(() =>
  import("./components/pages/Experimental/Experimental")
);

function App() {
  const [isDarkMode, setIsDarkMode] = useState(true);
  const navigate = useNavigate(); // Get current location
  const isInitialIzed = useRef(false);

  const handleNavigate = (path) => {
    startTransition(() => {
      // Navigate to the new path
      navigate(path); // or use navigate(path) with useNavigate hook
    });
  };

  const toggleTheme = () => {
    setIsDarkMode((prevMode) => {
      const newTheme = !isDarkMode ? "dark" : "light";
      setIsDarkMode(!isDarkMode);
      localStorage.setItem("theme", newTheme);
      console.log(`Setting theme to: ${newTheme}`);
      return !prevMode;
    });

    PlaySounds("click");
  };

  useEffect(() => {
    if (isInitialIzed.current) return;
    isInitialIzed.current = true;

    // Load theme preference from localStorage on component mount
    const savedTheme = localStorage.getItem("theme");
    console.log(`Loaded theme from localStorage: ${savedTheme}`); // Log to check
    if (savedTheme) {
      setIsDarkMode(savedTheme === "dark");
    } else {
      // Default to system preference
      const prefersDark = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
      setIsDarkMode(prefersDark);
    }
  }, []);

  useEffect(() => {
    // Change the theme dynamically by setting the data-theme attribute
    const theme = isDarkMode ? "dark" : "light";
    document.documentElement.setAttribute("data-theme", theme);
    // Save the theme preference to localStorage
    localStorage.setItem("theme", theme);
  }, [isDarkMode]);

  return (
    <ConstantsProvider>
      <GlobalStateProvider>
        <ImageProvider>
          <NavigationProvider handleNavigate={handleNavigate}>
            <Suspense
              fallback={<FontAwesomeIcon icon={faSpinner} spin size="3x" />}
            >
              <Routes>
                <Route path="/" element={<Navigate to="/home" />} />
                <Route path="/home" element={<Home />} />
                <Route path="/projects" element={<Projects />} />
                <Route path="/explorations" element={<Explorations />} />
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
              </Routes>
              <footer id="dark-mode-toggle">
                <span id="dark-mode-button" onClick={toggleTheme}>
                  {isDarkMode ? (
                    <span className="material-icons light-mode-icon">
                      light_mode
                    </span>
                  ) : (
                    <span className="material-icons dark-mode-icon">
                      dark_mode
                    </span>
                  )}
                </span>
              </footer>
            </Suspense>
          </NavigationProvider>
        </ImageProvider>
      </GlobalStateProvider>
    </ConstantsProvider>
  );
}

export default App;
